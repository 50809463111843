<template>
    <div>
        <h1>Success page</h1>
    </div>
</template>

<script>
export default ({
    name:"BoostPropertyFail",
})
</script>
